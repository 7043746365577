<form [formGroup]="nameForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput 
                cdkFocusInitial
                #filterInput 
                type="text"
                formControlName="name" 
                aria-label="New name to assign to the list"
            >
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button 
            color="accent"
            type="submit"
            aria-label="Close dialog submitting the new list name for saving"
        >Rename</button>
    </mat-dialog-actions>
</form>

<button mat-icon-button 
  color="accent"
  matTooltip="Cancel"
  class="button-cancel" 
  aria-label="Close the dialog, canceling the action"
  mat-dialog-close 
><mat-icon class="rotate-45" svgIcon="plus"></mat-icon></button>
