<div [innerHTML]="qrCode$ | async | safe: 'html'"></div>
<p><small>List members can scan this QR Code to add you to their list</small></p>
<p mat-dialog-content #test>{{(afAuth.user | async)?.uid}}</p>
<div mat-dialog-actions align="end">
    <button mat-icon-button #shareButton
        cdkFocusInitial
        color="accent"
        matTooltip="Copy UID to clipboard"
    >
        <mat-icon svgIcon="copy"></mat-icon>
    </button>
    <button mat-icon-button
        matTooltip="Close dialog"
        mat-dialog-close 
    >
        <mat-icon class="rotate-45" svgIcon="plus"></mat-icon>
    </button>
</div>
