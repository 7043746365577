<h2 mat-dialog-title [innerHTML]="data.title || 'Confirm Action'"></h2>
<mat-dialog-content *ngIf="!!data.content" [innerHTML]="data.content"></mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button 
    [mat-dialog-close]="false"
    cdkFocusInitial
  >{{data.decline || 'Cancel'}}</button>
  <button mat-raised-button 
    color="accent" 
    [mat-dialog-close]="true"
  >{{data.accept || 'Confirm'}}</button>
</mat-dialog-actions>
