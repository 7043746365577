<ng-container *ngIf="{ have: (itemsHave$ | async), need: (itemsNeed$ | async), cart: (cartItems$ | async) } as results">
    <section class="container-noitems" *ngIf="!results.need?.length && !results.cart?.length && !results.have?.length">
        <div>
            <h2>
                <span *ngIf="!this.filterService.filterTerm">No items in list</span>
                <span *ngIf="!!this.filterService.filterTerm">No items match filter term</span>
            </h2>
            <button mat-raised-button (click)="openCreateItemDialog()">Create Item</button>
        </div>
    </section>
    <section *ngIf="!!results.need?.length" class="container-need">
        <h2>Shopping List</h2>
        <app-list-need [items]="results.need" (moveToCart)="moveToCart($event)"></app-list-need>
    </section>
    <section *ngIf="!!results.cart?.length" class="container-cart">
        <app-list-cart [items]="results.cart" (clearCart)="clearCart()"
            (removeItem)="removeCartItem($event)"></app-list-cart>
    </section>
    <section *ngIf="!!results.have?.length" class="container-have">
        <h2>Things I Have</h2>
        <app-list-have [items]="results.have"></app-list-have>
    </section>
</ng-container>
