<mat-list role="list" class="container"> 
    <mat-list-item *ngFor="let list of (lists$ | async); trackBy: trackByID" 
        role="listitem" 
        [ngClass]="{ 'active': (listService.activeListSubject | async)?.id === list.id }"
    >
        <div class="clickable"
            (click)="setActiveList(list)">
            <div class="name">{{list.name}}</div>
        </div>
        <button mat-icon-button
            *ngIf="!!list?.personal"
            
            color="accent" 
            aria-label="Clear active list and only view personal list"
            matTooltip="View personal items"
            (click)="setActiveList(list)"
        >
            <mat-icon svgIcon="star"></mat-icon>
        </button>
        <button mat-icon-button 
            *ngIf="!list?.personal"
            color="accent" 
            [matMenuTriggerFor]="menu" 
            aria-label="Opens an extra menu of actions related to the list"
            matTooltip="Options"
        >
            <mat-icon svgIcon="options"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
            <ng-template matMenuContent>
                <button mat-menu-item 
                    (click)="setActiveList(list)"
                    matTooltip="Set list as active list to view it's items"
                >
                    <mat-icon svgIcon="star"></mat-icon>
                    <span>Set Active</span>
                </button>
                <button mat-menu-item 
                    (click)="editListUsers(list)"
                    matTooltip="Edit list's user who have access"
                >
                    <mat-icon svgIcon="person"></mat-icon>
                    <span>Users ({{list.users?.length}})</span>
                </button>
                <button mat-menu-item 
                    (click)="renameList(list)"
                    matTooltip="Change the name of the list"
                >
                    <mat-icon svgIcon="edit"></mat-icon>
                    <span>Rename</span>
                </button>
                <button mat-menu-item 
                    (click)="deleteList(list)"
                    matTooltip="Delete list"
                >
                    <mat-icon svgIcon="trash"></mat-icon>
                    <span>Delete</span>
                </button>
            </ng-template>
        </mat-menu>
    </mat-list-item>
</mat-list>

<div class="button-create">
    <button mat-fab 
        matTooltip="New list"
        color="primary"
        aria-label="Create new list entry for adding items against"
        (click)="listService.newList()"
    ><mat-icon svgIcon="plus"></mat-icon></button>
</div>
