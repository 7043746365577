<video #preview id="preview" [ngClass]="{ 'hidden': !showVideoElement }" style="max-width: 80vw; max-height: 10em;object-fit: scale-down;"></video>
<form [formGroup]="usersForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <mat-form-field
            [hintLabel]="(afAuth.user | async)?.uid === control.value ? 'Yourself' : ''"
            *ngFor="let control of usersArray.controls; index as i">
            <mat-label>User</mat-label>
            <input matInput #user
                #filterInput 
                type="text"
                [formControl]="$any(control)" 
                hint
                aria-label="User ID to grant access to the list"
            >
            <button mat-icon-button 
                matSuffix 
                matTooltip="Remove user"
                aria-label="Removes user from access to list"
                (click)="usersArray.removeAt(i)"
                type="button"
            ><mat-icon class="rotate-45" svgIcon="plus"></mat-icon></button>
            <button mat-icon-button
                matSuffix 
                *ngIf="hasCamera$ | async"
                matTooltip="Scan QR Code"
                (click)="scanCode(i)"
                type="button"
            ><mat-icon svgIcon="camera"></mat-icon></button>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button
            class="adduser"
            cdkFocusInitial
            type="button"
            aria-label="Add user to list of users granted access to the list"
            (click)="addUser()"
        >
            <mat-icon svgIcon="plus"></mat-icon>
            <span>Add User</span>
        </button>
        <button mat-raised-button 
            color="accent"
            type="submit"
            aria-label="Close dialog, saving list of users"
        >Save</button>
    </mat-dialog-actions>
</form>

<button mat-icon-button 
  color="accent"
  matTooltip="Cancel"
  class="button-cancel" 
  aria-label="Close the item edit dialog, canceling the action"
  mat-dialog-close
><mat-icon class="rotate-45" svgIcon="plus"></mat-icon></button>
