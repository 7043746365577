<form [formGroup]="itemGroup" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-list>
      <!-- Name Field -->
      <mat-list-item class="item-name">
        <mat-form-field class="full-width">
          <mat-label>Name</mat-label>
          <input type="text"
                cdkFocusInitial 
                matInput
                formControlName="name">
        </mat-form-field>
      </mat-list-item>
      <!-- Category Field -->
      <mat-list-item class="item-category">
        <mat-form-field class="full-width">
          <mat-label>Category</mat-label>
          <input type="text"
                matInput
                formControlName="category"
                [matAutocomplete]="autoCategories">
          <mat-autocomplete #autoCategories="matAutocomplete">
            <mat-option *ngFor="let option of (filteredCategoryOptions$ | async)" [value]="option">{{option}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </mat-list-item>
      <!-- Measurement Field -->
      <mat-list-item class="item-measurement">
        <mat-form-field class="full-width">
          <mat-label>Measurement</mat-label>
          <mat-select matInput formControlName="measurement">
            <ng-container *ngFor="let measurement of (measurements$ | async)">
              <ng-container [ngSwitch]="!!measurement.id">
                <mat-option *ngSwitchCase="true" [value]="measurement.id">{{ measurement.name | titlecase }} ({{ measurement.unit }})</mat-option>
                <mat-option *ngSwitchCase="false" [value]="null">None</mat-option>
              </ng-container>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </mat-list-item>
      <!-- Amount Field -->
      <mat-list-item class="item-amount">
        <mat-form-field class="full-width">
          <mat-label>Amount</mat-label>
          <input type="number"
                min="0"
                step="0.01"
                matInput
                formControlName="amount">
        </mat-form-field>
      </mat-list-item>
      <!-- Estimated Toggle -->
      <mat-list-item>
        <mat-checkbox formControlName="estimated">Estimated</mat-checkbox>
      </mat-list-item>
      <!-- Quanity Field -->
      <mat-list-item class="item-quantity">
        <mat-form-field class="full-width">
          <mat-label>Quantity</mat-label>
          <input type="number"
                min="1"
                step="1"
                matInput
                formControlName="quantity">
        </mat-form-field>
      </mat-list-item>
      <!-- Obtained Toggle -->
      <mat-list-item class="item-obtained">
        <span>I Need</span>
        <mat-slide-toggle formControlName="obtained"></mat-slide-toggle>
        <span>I Have</span>
      </mat-list-item>
      <mat-list-item class="favourite">
        <mat-checkbox formControlName="favourite">Favourite</mat-checkbox>
      </mat-list-item>
    </mat-list>
  
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button
            color="accent"
            type="submit"
    >Save</button>
  </mat-dialog-actions>
  
</form>

<button mat-icon-button 
  color="accent"
  matTooltip="Cancel"
  class="button-cancel" 
  aria-label="Close the dialog, canceling the action"
  mat-dialog-close 
><mat-icon class="rotate-45" svgIcon="plus"></mat-icon></button>
