
<button mat-icon-button
  (click)="openFilter($event)"
  aria-label="Opens an input field for input to filter active list of items"
  matTooltip="Filter active list"
>
  <mat-icon svgIcon="search"></mat-icon>
</button>

<mat-form-field #fieldField 
  appearance="legacy" 
  [ngClass]="{ 'hidden': !filterOpen }" 
  [attr.aria-hidden]="!filterOpen"
  floatLabel="never"
>
  <input matInput 
    #filterInput 
    type="text" 
    [formControl]="filterControl" 
    placeholder="Filtering Term" 
    aria-label="Input that filters active list by the typed value" 
    (blur)="onBlur()"
  >
  <button mat-icon-button 
    *ngIf="filterControl.value" 
    matSuffix 
    mat-icon-button 
    matTooltip="Clear filter value"
    aria-label="Clears the filter value for the active list, restoring it to the full list"
    (click)="filterControl.setValue('')"
  >
    <mat-icon class="rotate-45" svgIcon="plus"></mat-icon>
  </button>
</mat-form-field>
