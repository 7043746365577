
<div class="basket" *ngIf="!!inputItems?.length">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon svgIcon="cart"></mat-icon>
                <span>Shopping Cart</span>
            </mat-panel-title>
            <mat-panel-description>{{inputItems?.length || 0}}</mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <ul class="items">
                <li class="item" *ngFor="let item of inputItems; trackBy: trackByItemID" tabindex="0">
                    <app-list-item [item]="item">
                        <button mat-icon-button color="accent" (click)="removeCartItemEmitter.emit( item.id );" type="button"
                            matTooltip="Remove item from shopping cart">
                            <mat-icon svgIcon="checkbox-filled"></mat-icon>
                        </button>
                    </app-list-item>
                </li>
            </ul>
            <div class="cart-actions">
                <button mat-raised-button class="clear" type="button" (click)="clearCart()"
                    matTooltip="Clear shopping cart, moving items to main list">Empty</button>
                <button mat-raised-button class="checkout" color="accent" type="button" (click)="checkout()"
                    matTooltip="Checkout all cart items moving them to the 'have' list">Checkout</button>
            </div>
        </ng-template>
    </mat-expansion-panel>
</div>
