export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDEKN2rbrU6V7oleIC5zvFvfarYjMJhgJ8",
    authDomain: "grocerment.firebaseapp.com",
    projectId: "grocerment",
    storageBucket: "grocerment.appspot.com",
    messagingSenderId: "575679077553",
    appId: "1:575679077553:web:81fe39aecdd4a1fa6e5690"
  }
};
