<ng-content></ng-content>
<div class="name">
    <span>{{item?.name || '(No item name)'}}</span>
    <ng-container *ngIf="!!item?.favourite">
        <span matTooltip="Favoured">*</span>
    </ng-container>
</div>
<div *ngIf="!!item?.measurement && !!item?.amount">
    <span *ngIf="!!item?.estimated">~</span>
    <span>{{item?.amount}}</span>
    <ng-container *ngIf="!!item?.measurement">
        <ng-container *ngIf="(item?.measurement | getMeasurement | async) as measurement" >
            <span [matTooltip]="(measurement.name | titlecase) || 'No measurement name'">{{measurement?.unit || '(No measurement unit)'}}</span>
        </ng-container>
    </ng-container>
</div>
